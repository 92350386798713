import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

import HubspotField from '../components/HubspotField';
import {
  Blog,
  Card,
  Footer,
  Layout,
  Section,
  Hero,
  ImageGroup,
  MainNavigation,
  RichText,
  SocialLinks,
  Testimonial,
} from '../components';

const PageTemplate = ({ data }) => {
  const { navItems: mainNavItems } = data.mainNav;
  const { navItems: footerNavItems } = data.footerNav;

  return (
    <Layout>
      <header>
        <MainNavigation navItems={mainNavItems} activePage={'slug'} />
      </header>
      <main>
        <Section>
          <Blog />
          <h1>
            <HubspotField field="name" />
          </h1>
          <p>
            <HubspotField field="blog_post_author.display_name" /> <HubspotField field="publish_date_localized" />
          </p>

          <div>
            <HubspotField field="post_body" />
          </div>

          <SocialLinks type="sparkol" />
        </Section>
      </main>
      <Footer navItems={footerNavItems} activePage={'slug'} />
    </Layout>
  );
};

PageTemplate.propTypes = {
  data: PropTypes.object.isRequired,
};

export default PageTemplate;

export const query = graphql`
  query BlogPostPageBySlugQuery {
    mainNav: contentfulNavigation(contentful_id: { eq: "2meGLRtPVtQHM3nQ5O9ybD" }) {
      ...NavigationFragment
    }
    footerNav: contentfulNavigation(contentful_id: { eq: "4oCRZPRLFLbMWGwFsXcHjI" }) {
      ...NavigationFragment
    }
  }
`;
