import React from 'react';

export default class HubspotField extends React.Component {
  constructor(props) {
    super(props);

    this.isClient = typeof window !== 'undefined' && window.document?.createElement;
    this.state = {
      text: '',
    };
  }

  componentWillMount() {
    if (this.isClient) {
      let actualText = document.getElementById(this.props.field)?.innerHTML;
      this.setState({
        text: actualText,
      });
    }
  }

  render() {
    if (this.isClient) {
      return <span dangerouslySetInnerHTML={{ __html: this.state.text }}></span>;
    } else {
      let fieldString = `{{content.${this.props.field}}}`;
      return <span id={this.props.field}>{fieldString}</span>;
    }
  }
}
